﻿@import "../variables.scss";
@import "../mixins.scss";

.employee-card {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__image {
        background: $white;
        border-radius: 15px;
        border: 1px solid $secondary;
        box-shadow: 0px 0px 40px rgba(0,0,0,0.15);
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(sm) {
            flex: 0 1 300px;
            max-width: 300px;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 1 500px;
            max-width: 500px;
        }

        @media print {
            border: none;
        }
    }

    &__content {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            background: $white;
            box-shadow: 0px 0px 40px rgba(0,0,0,0.15);
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            margin-top: 50px;
            margin-bottom: 50px;
            padding: 1rem 1.25rem;
            flex: 1;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem;

        @media print {
            margin: 0;
        }
    }

    &__divider {
        position: relative;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1px;
        width: 120px;
        margin: 1rem auto;
        background-color: $secondary;

        > svg {
            width: 30px;
            height: 30px;
            fill: $secondary;
        }

        &.-with-icon {
            background: none;
            height: 30px;

            &:before, &:after {
                content: "";
                background-color: $secondary;
                width: 85px;
                height: 1px;
                position: absolute;
            }

            &:before {
                margin-right: 60px;
                transform: translateX(-50%);
            }

            &:after {
                margin-left: 60px;
                transform: translateX(50%);
            }
        }
    }

    &__title {
        color: $secondary;
        font-size: 1.3rem;
    }

    &__name {
        text-align: center;
    }

    &__meta {
        display: flex;
        flex-direction: column;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        margin: auto 0 0 0;
        padding: 0;
    }

    &__action-item {
        padding: 0.25rem 0.5rem;

        @include media-breakpoint-up(sm) {
            padding: 0.5rem 0.75rem;
        }
    }

    &__action-link {
        color: $black;
        text-decoration: none;

        > svg {
            width: 20px;
            height: 20px;
            fill: $secondary;
            margin-right: 0.5rem;
        }

        &:hover {
            > span {
                text-decoration: underline;
            }
        }
    }
}