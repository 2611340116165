﻿@import "../mixins.scss";

.page-content {
    .content {
        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
    }
}

.sidebar {
    margin: 20px 0;

    @include media-breakpoint-up(md) {
        margin-right: 20px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
    }

    .box {
        background-color: $white;
        box-shadow: $box-shadow-base;
        border-radius: 15px;
        margin: 50px 0 30px 0;
        padding: 30px;

        &:first-child {
            margin-top: 0;
        }

        .box-header {
            position: relative;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;

            .box-caption {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $primary;
                color: $white;
                padding: 0.3rem 0.5rem;
                margin: auto auto 0 auto;
                width: 100%;
                min-height: 90px;
                border-radius: 20px;
                position: relative;
                overflow: hidden;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    clear: both;
                    transition: all ease-in-out 0.25s;
                    transform: translate(-50%, 100%);
                }

                &::before {
                    left: 50%;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $secondary;
                }

                &::after {
                    left: 50%;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background: $secondary;
                }

                &__title {
                    font-size: 0.9rem;
                    font-weight: 400;
                    text-align: center;
                    text-transform: uppercase;
                    position: relative;
                    margin: 0;
                }
            }
        }
    }
}