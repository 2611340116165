﻿@import "../variables.scss";
@import "../mixins.scss";

.btn {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding: 10px 25px;
        min-width: 180px;
    }

    &.btn-secondary {
        color: $white;

        &:hover {
            background-color: darken($secondary, 5%);
        }
    }

    &.btn-outline {
        background-color: $white;
        border-color: $secondary;

        &:hover {
            color: $white;
            background-color: darken($secondary, 5%);
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        background: linear-gradient(to bottom,rgba(darken($secondary, 5%),0),rgba($white,0.5) 50%,rgba(darken($secondary, 5%),0));
        transform: rotateZ(120deg) translate(3em,7.5em);
        animation: sheenDefault 1s forwards;
    }

    &:hover::after, &:focus::after {
        animation: sheen 1s forwards;
    }
}


// Reverse to default on mouse out: https://stackoverflow.com/a/41225386
@keyframes sheenDefault {
    0% {
        transform: rotateZ(120deg) translate(1em, -9em);
    }

    100% {
        transform: rotateZ(120deg) translate(3em,7.5em);
    }
}

// Sheen effect: https://cssanimation.rocks/pseudo-elements/

@keyframes sheen {
    100% {
        transform: rotateZ(120deg) translate(1em, -9em);
    }
}