﻿@import "../mixins.scss";

.department-section {
    &__image {
        display: inline-flex;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__text {
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
    }
}