﻿@import "../../mixins.scss";

.block-cta {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    margin: 1rem 0;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;

    &__content {
        padding: 2rem;
        max-width: 100%;

        @include media-breakpoint-up(xl) {
            padding: 3rem;
            max-width: 80%;
        }

        @include media-breakpoint-up(xl) {
            padding: 5rem;
        }
    }
}