﻿@import "../mixins.scss";
@import "../variables.scss";

.breadcrumb {
    list-style: none;
    padding: 0;
    color: #aaa;
    font-size: 0.9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &__link {
        &, &:hover {
            color: inherit;
        }
    }

    &__item {
        display: inline;

        &:not(:last-child) {
            margin-right: 0.25rem;

            &::after {
                content: "\003E";
            }
        }
    }
}
