﻿$font-family-base: 'Nunito', sans-serif;
$headings-font-family: 'Nunito', sans-serif;
$enable-responsive-font-sizes: true;

$primary: #282d40;
$secondary: #c08e58;
$gray-base: #707070;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light-gray": #F5F5F5,
    "medium-gray": #bfC0C0,
    "light-blue": #11497A,
    "medium-blue": #0B3356,
    "dark-blue": #09253D,
    "brown": #60472C
);

$box-shadow-base: 0px 0px 40px rgba(#000,0.15);

$body-color: #000;

$h1-font-size: 2.25rem;
$h2-font-size: 2rem;

$headings-font-weight: 400;

$lead-font-size: 1.5rem;

$link-color: $secondary;

$input-box-shadow: none;
$input-placeholder-color: #6c757d;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$grid-gutter-width: 7px;

// Navigation color
$navbar-light-color: $body-color;
$navbar-light-hover-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-light-disabled-color: $body-color;

$custom-file-text: (
    en: "Browse",
    da: "Vælg"
);

$custom-select-bg-size: 12px 12px !default; // In pixels because image dimensions
$custom-select-indicator-color: $secondary !default;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$custom-select-indicator-color}' d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1400px,
    xxl: 1600px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px
);

@import "../../node_modules/bootstrap/scss/variables";