﻿@import "../variables.scss";

.search-block {
    margin: 30px 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .input-group-prepend {
        .form-control {
            background: url("/dist/img/search.svg") $white center no-repeat;
            background-position: left center;
            background-size: contain;
            border-radius: 50px 0 0 50px;
            padding-left: 40px;
            height: auto;
        }

        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .search-results {
        margin: 30px 0;

        &__header {
            color: #707070;
            font-size: 20px;
            margin-bottom: 10px;
        }

        .search-result-item {
            border-bottom: 1px solid #dedede;
            padding: 20px 0;

            &__heading > a {
                color: $body-color;
            }

            &__text {
                margin: 0;
                word-wrap: break-word;
            }
        }
    }
}
