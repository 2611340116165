﻿@import "../variables.scss";
@import "../mixins.scss";

@import "../variables.scss";
@import "../mixins.scss";

.article-header {
    position: relative;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
    }

    &__divider {
        position: relative;
        border: 0;
        height: 30px;
        width: 350px;
        max-width: 100%;
        margin: 1rem auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(sm) {
            display: none;
        }

        > svg {
            height: 30px;
            width: 30px;
            fill: $secondary;
        }

        &::before, &::after {
            content: "";
            background-color: $secondary;
            width: 120px;
            height: 1px;
            position: absolute;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }

    &__text {
        font-size: 18px;
    }

    @include media-breakpoint-up(lg) {

        .article-header__divider {

            > svg {
                fill: $white;
            }

            &::before, &::after {
                background-color: $white;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .article-header__divider {
            margin-top: 15px;
            order: 5;
        }
    }
}