﻿@import "../variables.scss";
@import "../mixins.scss";

.timeline {
    width: 100%;

    > ul {
        margin: 0;
        padding: 0;

        > li {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            align-items: center;

            .date {
                background-color: $secondary;
                color: $white;
                width: 120px;
                font-size: 24px;
                text-align: center;
                padding: 10px 15px;
                border-radius: 15px;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 50%;
                    top: 0;
                    transform: translateY(28px);
                    background-color: $secondary;
                    z-index: -1;
                }

                &::before {
                    left: 0;
                }

                &::after {
                    right: 0;
                }
            }

            .content {
                text-align: center;
                border-radius: 10px;
                overflow: hidden;
                margin: 20px 0;

                h5, p {
                    margin-bottom: 0;
                    padding: 5px;
                }

                h5 {
                    font-weight: bold;
                }

                p {
                    color: #707070;
                    font-size: 0.9rem;
                }
            }

            &:first-child > .date::before {
                content: none;
            }

            &:last-child > .date::after {
                content: none;
            }
        }
    }
}