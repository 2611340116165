﻿@import "../variables.scss";
@import "../mixins.scss";

.block-info {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    border-radius: 15px;
    padding: 30px;
    margin: 15px 0;
    color: $white;

    @include gradient-directional($start-color: rgba(theme-color("light-blue"), 1), $end-color: rgba(theme-color("dark-blue"), 1), $deg: 90deg);

    &__text {
        flex: 0 1 auto;
        padding-right: 15px;
    }

    &__more {
        display: flex;
        flex: 0 0 25%;

        .btn {
            margin: 30px auto auto auto;
        }
    }

    @include media-breakpoint-down(md) {
        flex-flow: wrap;
        margin-left: 10px;
        margin-right: 10px;

        &__text {
            flex: 0 0 100%;
            padding: 0;
        }

        &__more {
            flex: 0 0 100%;

            .btn {
                margin: 30px auto 0 auto;
            }
        }
    }
}
