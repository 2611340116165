﻿@import "../../node_modules/bootstrap/scss/mixins";
@import "variables.scss";

/* 
    Custom mixins:

    escape-svg function is part of Bootstrap functions
*/

@mixin expandIcon($color: '#000000', $width: 16px, $height: $width) {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{height}' fill='#{$color}'><path d='M8.75 1.151V7.25l6.099.001v1.5h-6.1l.001 6.099h-1.5v-6.1L1.15 8.75v-1.5H7.25L7.25 1.15z' /></svg>"));
}

@mixin collapseIcon($color: '#000000', $width: 16px, $height: $width) {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{height}' fill='#{$color}'><path d='M14.849 7.25v1.5H1.15v-1.5z' /></svg>"));
}

@mixin subjectIcon($color: '#fff', $backgroundColor: $secondary, $width: 50px, $height: $width) {
    background-color: $backgroundColor;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    height: $width;
    width: $height;
}


@mixin tabCurve($color: '#000000') {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.85 17.04' fill='#{$color}'><path d='M0,0V17H19.85C13.1,17,1.43,17.3,0,0Z'/></svg>"));
    background-repeat: no-repeat;
}

@mixin tabCurveSmall($color: '#000000') {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.47 14.93' fill='#{$color}'><path d='M0,0V14.93H11.47C7.69,14.85,1.27,15.15,0,0Z'/></svg>"));
    background-repeat: no-repeat;
}


// Mixin to generate specific colunmn size on print: https://gist.github.com/TheSharpieOne/27f338c93d5de166ed1e35f5fc753228

@mixin make-print-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $name: "pr") {
    @media print {
        // Common properties
        %grid-column-print {
            position: relative;
            width: 100%;
            min-height: 1px; // Prevent columns from collapsing when empty
            padding-right: ($gutter / 2);
            padding-left: ($gutter / 2);
        }
        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
            .col-#{$name}-#{$i} {
                @extend %grid-column-print;
            }
        }

        .col-#{$name} {
            @extend %grid-column-print;
        }
        // Provide basic `.col-{bp}` classes for equal-width flexbox columns
        .col-#{$name} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .col-#{$name}-auto {
            flex: 0 0 auto;
            width: auto;
        }

        @for $i from 1 through $columns {
            .col-#{$name}-#{$i} {
                @include make-col($i, $columns);
            }
        }

        .order-#{$name}-first {
            order: -1;
        }

        @for $i from 1 through $columns {
            .order-#{$name}-#{$i} {
                order: $i;
            }
        }
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
            .offset-#{$name}-#{$i} {
                @include make-col-offset($i, $columns)
            }
        }
    }
}