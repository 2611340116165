﻿@import "../mixins.scss";

.employees-wrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 2rem;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;

    .employee {
        color: #707070;
        margin-bottom: 2rem;
        text-decoration: none;
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 30px 15px;
        text-align: center;

        > article {
            background: $white;
            box-shadow: $box-shadow-base;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 2rem;
        }

        &__image {
            display: inline-block;
            max-width: 220px;
            margin: -4rem auto 0 auto;
            width: 100%;
            border-radius: 15px;
            border: 1px solid $secondary;
            box-shadow: $box-shadow-base;
            background: $white;
            overflow: hidden;
            transition: all ease-in-out .25s;

            &.-no-image {
                > img {
                    transform: translateY(15px);
                }
            }

            img {
                filter: grayscale(1);
                width: 100%;
                height: 100%;
                transform: translateZ(0);
                transition: all ease-in-out .25s;
            }
        }

        span {
            display: block;
        }

        &__title {
            color: $black;
            font-size: 1rem;
            margin: 15px 0 0;
        }

        &__name {
            color: gray("800");
            margin: 15px 0 20px;
        }

        &__info {
            margin-bottom: 15px;
        }

        &__read-more {
            @extend .btn;
            @extend .btn-secondary;
            margin-top: auto;
        }

        @media (min-width: 400px) {
            width: 50%;
            max-width: 50%;
            flex: 0 0 50%;
            padding: 10px;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            max-width: 50%;
            flex: 0 0 50%;
        }

        @include media-breakpoint-up(xl) {
            min-width: 340px;
            width: 25%;
            max-width: 25%;
            flex: 0 0 25%;
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }
}

.employee-section {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}