﻿@import "../mixins.scss";

.block-testimonial {
    min-height: 400px;
    color: #fff;
    text-align: center;
    padding: 40px 0;
    position: relative;
}

.swiper-container.testimonial {
    height: auto;
    min-height: 120px;
}

.testimonial {

    .user-quote {
        font-size: 1.2rem;
        margin: 0 auto;
        padding: 1rem;
        max-width: 700px;
    }

    .name {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.04em;
        line-height: 35px;
        text-align: left;
    }

    .designation {
        font-size: 14px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #fff;
        opacity: 0.65;
    }
}

.gallery-thumbs-container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    max-width: 880px;

    @extend .container;

    @include media-breakpoint-down(md) {
        max-width: 95vw;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100vw;
    }
}

.gallery-thumbs {
    max-width: 650px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        max-width: 560px;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }

    .swiper-wrapper {
        align-items: center;

        .swiper-slide {
            position: relative;
            min-height: 280px;

            .person {
                cursor: pointer;
                width: 200px;
                height: 200px;
                margin: auto;
                transition: all 0.3s ease-in-out;
                transform: scale3d(0.8, 0.8, 1);

                &__avatar {
                    overflow: hidden;
                    border-radius: 15px;
                    border: 1px solid $secondary;
                    background: $white;
                    margin: 15px;

                    img {
                        filter: blur(1px) grayscale(1);
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .person-details {
                    opacity: 0;
                    transition: all ease-in-out 0.25s;

                    &__name {
                        font-size: 1.6rem;
                    }

                    &__designation {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .swiper-slide-active,
        .swiper-slide-duplicate-active {
            .person {
                transform: scale3d(1.0, 1.0, 1);

                img {
                    filter: blur(0) grayscale(0);
                }

                .person-details {
                    opacity: 1;
                }
            }
        }
    }
}

.testimonial-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include gradient-directional($start-color: rgba(theme-color("secondary"), 0.9), $end-color: rgba(theme-color("brown"), 0.9), $deg: 270deg);

    .bg-image-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        img {
            position: relative;
            filter: grayscale(100%);
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @include gradient-directional($start-color: rgba(theme-color("secondary"), 0.9), $end-color: rgba(theme-color("brown"), 0.9), $deg: 270deg);
        }
    }

    .swiper-navigation-container {
        top: 50%;
        left: 0;
        right: 0;

        &::before {
            height: 1px;
            width: auto;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 70px;
            right: 70px;
        }

        .swiper-button-prev, .swiper-button-next {
            color: $white;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            margin: 0;
            z-index: 3;

            &::after {
                font-size: 1.5rem;
            }
        }

        .swiper-button-prev {
            transform: translateY(-50%);
        }

        .swiper-button-next {
            transform: translateY(-50%);
        }

        .swiper-pagination {
            bottom: 0;
            left: 50%;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            transform: translateY(-30px);

            .swiper-button-prev, .swiper-button-next {
                border: 1px solid $white;
            }
        }

        @include media-breakpoint-down(sm) {
            .swiper-button-prev, .swiper-button-next {
                &::after {
                    font-size: 2.5rem;
                }
            }

            .swiper-button-prev {
                transform: translateY(-100%);
            }

            .swiper-button-next {
                transform: translateY(-100%);
            }
        }
    }

    .swiper-pagination {

        .swiper-pagination-bullet {
            position: relative;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            color: #000;
            opacity: 1;
            background: #fff;
        }

        .swiper-pagination-bullet-active {
            &::before {
                content: '';
                display: block;
                position: absolute;
                background: $secondary;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                top: calc(50% - 5px);
                left: calc(50% - 5px);
            }
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .user-saying {
        max-width: 800px;
        margin: 0 auto;
    }

    .quote-icon {
        display: block;
        margin: 0 auto;

        svg {
            width: 80px;
            height: 80px;
            fill: $secondary;
        }
    }

    .quote {
        width: 75%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20px auto;

        p {
            font-size: 18px;
            font-weight: 300;
            line-height: 1.5;
            margin: 0;
        }
    }

    @include media-breakpoint-up(md) {

        .swiper-navigation-container::before {
            content: "";
        }

        .quote {
            width: 50%;
        }
    }
}