﻿@import "../variables.scss";
@import "../mixins.scss";

.department-list {
    .department {
        border-bottom: 1px solid $secondary;
        color: $gray-base;
        padding: 1rem 0;

        &__name,
        &__address {
            margin: 0;
        }

        &__name {
            color: $black;
            font-weight: 700;
        }
    }
}