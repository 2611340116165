﻿@import "../variables.scss";
@import "../mixins.scss";

// https://vanillaframework.io/docs/patterns/accordion

.p-accordion {
    .p-accordion__list {
        list-style-type: none;
        margin: 0 0 1.5rem 0;
        padding: 0;

        .p-accordion__tab,
        .p-accordion__tab--with-title {
            background-color: inherit;
            border: 0;
            border-radius: 0;
            justify-content: flex-start;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 3rem;
            text-align: left;
            transition-duration: 0s;
            width: 100%;
            z-index: 2;
        }

        .p-accordion__title {
            margin-bottom: 0;
            padding-top: 0;
            position: relative;
            font-weight: 400;
            line-height: 2rem;
        }

        .p-accordion__tab--with-title {

            &[aria-expanded='true'] .p-accordion__title::before {
                @include collapseIcon($color: $secondary);
            }

            &[aria-expanded='false'] .p-accordion__title::before {
                @include expandIcon($color: $secondary);
            }

            .p-accordion__title .p-accordion__panel {
                margin: 0;
                overflow: auto;
                padding-left: 4rem;
            }
        }

        .p-accordion__tab {
            background-position: top calc(.5rem - 1px + 0.25rem) right 0;
            background-repeat: no-repeat;

            &[aria-expanded='false'] {
                @include expandIcon($color: $secondary);
            }

            &[aria-expanded='true'] {
                @include collapseIcon($color: $secondary);
            }
        }

        .p-accordion__panel {
            margin-top: 0.5rem;
        }

        .p-accordion__panel[aria-hidden='true'] {
            display: none;
        }

        .p-accordion__group {
            background-color: $white;
            border: 1px solid #cecece;
            border-radius: 15px;
            margin-bottom: 1.25rem;
            padding: 1.5rem;
            overflow: hidden;
            position: relative;
        }
    }
}
