﻿@import "../mixins.scss";

.hero {
    position: relative;
    overflow: hidden;

    .hero-caption-container {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        color: $white;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            top: 15%;
            transform: none;
        }

        @include media-breakpoint-down(sm) {
            top: 20%;
        }

        @extend .container;

        .hero-caption {

            &__header {
                color: #fff;
                margin-bottom: 2rem;

                @include media-breakpoint-down(md) {
                    font-size: 2.5rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 3rem;
                }
            }

            &__text {
                color: $white;
                margin-bottom: 2rem;
            }

            @include media-breakpoint-up(lg) {
                max-width: 60%;
                padding-top: 50px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 50%;
                background-image: url("/dist/img/logo-signature.svg");
                background-repeat: no-repeat;
                background-position: top left;
                background-size: auto 120px;
                padding-top: 130px;
            }

            @include media-breakpoint-down(md) {
                padding: 30px;
                text-align: center;

                &__text {
                    display: none;
                }
            }
        }
    }
}