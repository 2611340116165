﻿@import "../variables.scss";
@import "../mixins.scss";

.sub-navigation {
    ul {
        li {
            position: relative;
            margin: 0;

            &.has-submenu {
                > a::before {
                    @include caret-right();
                    content: "";
                    display: inline-block;
                    color: $secondary;
                    margin: auto 0;
                    position: absolute;
                    left: -0.75rem;
                    top: 1rem;
                }

                &.active {
                    > a::before {
                        transform: rotate(45deg);
                    }
                }
            }

            a {
                color: $black;
                border-bottom: 1px solid $secondary;
                display: flex;
                text-align: left;
                padding: 0.5rem 0;
            }

            &.current {
                > a {
                    font-weight: 700;
                }
            }

            .submenu {
                padding-left: 1rem;
            }
        }
    }
}