﻿@import "../../node_modules/glightbox/dist/css/glightbox.css";

@import "../variables.scss";
@import "../mixins.scss";

.umb-block-list {
    img {
        @extend .img-fluid;
    }
}

.block-text,
.block-image {
    padding-bottom: 20px;
}

.block-image,
.block-text-image {
    img {
        border-radius: 15px;
    }
}

.block-image {
    figure > figcaption {
        color: #707070;
        font-size: 0.9rem;
        margin-top: 0.3rem;
    }
}

.block-text {
    word-break: break-word;
}

.block-text-image {
    padding: 2rem 0;

    @include media-breakpoint-up(md) {
        padding: 4.5rem 0;
    }

    .image-wrapper {
        position: relative;
        display: inline-flex;

        &.-has-embed {
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 200px;
                height: 200px;
                transform: translate(-50%, -50%);
                background: url("/dist/img/play.svg") no-repeat;
                pointer-events: none;
                transition: all ease-in-out 0.25s;
                opacity: 0.8;
            }

            &:hover:before {
                opacity: 1;
            }
        }
    }
}

.block-presentation {
    background: #ccc;
    position: relative;

    .block-presentation-caption-container {
        position: absolute;
        margin: 0 auto;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        .block-presentation-caption {
            display: flex;
            flex-direction: column;

            &__tag-line {
                color: $secondary;
                font-size: 1.25rem;
                font-weight: 400;
            }

            &__heading,
            &__text {
                color: $white;
            }

            &__text {
                margin-top: 0.75rem;
            }

            &__link {
                margin-top: 0.75rem;
                margin-right: auto;
            }

            &__graphics {
                display: flex;
                justify-content: flex-end;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;

                &__link,
                &__graphics {
                    margin: auto;
                }

                &__link {
                    order: 5;
                }
            }
        }
    }
}

.block-faq {
    background-color: theme-color("light-gray");
    padding: 2rem 0;

    @include media-breakpoint-up(md) {
        padding: 4.5rem 0;
    }
}

.block-map {
    padding: 2rem 0;

    @include media-breakpoint-up(md) {
        .textblock-area {
            margin-top: 3rem;
        }
        .quote {
            margin-top: 3.75rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .block-text,
    .block-image,
    .block-text-image,
    .block-focus-points,
    .block-faq,
    .block-map {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}