﻿blockquote.quote {
    font-size: 28px;
    padding: 0 25px;
    position: relative;
    text-align: center;

    &:before, &:after {
        content: "";
        background-image: url("/dist/img/quote.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        display: inline-flex;
        width: 22px;
        height: 22px;
        top: 0;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
}
