﻿@import "../variables.scss";
@import "../mixins.scss";

.sub-header {
    position: relative;
    margin-bottom: 1rem;

    &__image {
        position: relative;
    }
}