﻿@import "../mixins.scss";

.site-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    color: $white;
    background: rgba(theme-color("dark-blue"), 0.7);

    @include media-breakpoint-down(md) {
        position: fixed;
        background: rgba(theme-color("dark-blue"), 1);
    }
}

.site-top {
    padding: 0;

    .site-top-container > div {
        display: flex;
        flex-wrap: wrap;
    }

    .site-top-logo__wrapper {
        display: inline-flex;
        align-items: center;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            z-index: 120;
        }

        .site-top-logo img {
            width: 220px;
            max-height: 100px;

            @include media-breakpoint-down(md) {
                width: auto;
                max-height: 40px;
                margin-left: 15px;
            }
        }
    }

    .site-top-links {
        position: absolute;
        top: 0;
        right: 0;
        color: $white;
        font-size: 0.9rem;

        > ul {
            margin: 5px 15px;

            > li {
                > a {
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }

                    svg {
                        fill: currentColor;
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }
        }
    }

    .site-top-info__wrapper {
        display: flex;
        margin: auto;

        .site-top-info {
            display: flex;
            flex-direction: column;
            padding: 0 15px;

            @include media-breakpoint-up(xl) {
                padding: 0 25px;
            }

            &:last-child {
                padding-right: 10px;
            }

            &:not(:last-child) {
                border-right: 1px solid $secondary;
            }

            &__header {
                display: flex;
                align-items: center;
                font-size: 1.25rem;

                img {
                    margin-right: 0.5rem;
                }
            }

            &__link {
                color: $white;
                text-decoration: none;
            }

            &__text {
                font-size: 14px;
                margin-top: 0;
            }
        }
    }

    .search-wrapper {

        @include media-breakpoint-up(xl) {
            width: 265px;
            margin: auto;
        }

        .field-wrapper {
            position: relative;
            margin: 0;

            input[type="search"] {
                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    -webkit-appearance: none;
                }
            }

            .form-control {
                border-radius: 15px;
                border: 1px solid $secondary;
                background: $white;
            }

            .btn-search {
                background: none;
                border: none;
                box-shadow: none;
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                margin: 0;
                height: 100%;

                .search-icon {
                    display: inline-flex;
                    height: 100%;

                    > svg {
                        fill: none;
                        stroke: $secondary;
                        height: auto;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .site-top-links,
        .site-top-info__wrapper {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .site-top-main-menu-inner {
        flex: 0 0 100%;
    }
}

// Main menu
.site-top-main-menu {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    transform: translateX(0);
    transition: all 0.25s ease-in;

    @include media-breakpoint-down(md) {
        margin: 0;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__item {
        display: flex;
        flex-flow: row wrap;
        font-size: 1rem;
        position: relative;
        padding: 0;

        + .site-top-main-menu__item {

            @include media-breakpoint-up(md) {
                margin-left: 0.5rem;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 2rem;
            }
        }

        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $secondary;
            font-size: 1.125rem;
            align-items: center;

            button.dropdown-toggle {
                background: none;
                box-shadow: none;
                border: 0;
                text-indent: -999px;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                width: 1.5rem;
                height: 1.5rem;

                &::after {
                    color: $white;
                    margin: 0;
                }
            }
        }
    }

    &__link {
        display: block;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        padding: 0.5rem 0;

        @include media-breakpoint-up(md) {
            border-bottom: 2px solid transparent;
        }

        @include media-breakpoint-down(md) {
            text-transform: none;
            flex: 1 1 auto;
        }

        &, &:hover {
            color: $white;
            text-decoration: none;
        }

        &:hover, .active & {
            border-color: $white;
        }

        &.dropdown-toggle {
            &::after {
                vertical-align: middle;
                margin-left: 5px;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__item.active {

            & {
                > a {
                    color: $secondary;
                }
            }

            li.active {
                > a {
                    color: lighten($secondary, 10%);
                }
            }
        }
    }

    &__wrapper {
        width: 100%;
        background: theme-color("medium-blue");

        @include media-breakpoint-up(md) {
            display: block !important;
            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            &.sticky {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: $zindex-fixed;
            }
        }
    }
}

.site-top-main-dropdown {
    color: $body-color;
    padding: 10px 20px;
    min-width: 200px;
    list-style: none;

    @include media-breakpoint-up(lg) {
        display: none;
        position: absolute;
        left: -12px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.25);
        background-color: $white;
        z-index: 5;
    }

    @include media-breakpoint-down(md) {
        position: relative;
        background: none;
        border: none;
        width: 100%;
        padding: 0 0 0 1rem;
        margin-top: 0.25rem;
        margin-bottom: 1rem;
    }

    > li {
        display: flex;
        flex-flow: row wrap;
        padding: 2px;

        > a {
            color: inherit;
            display: flex;
            flex-flow: row wrap;

            &:hover {
                color: $link-hover-color;
            }

            @include media-breakpoint-down(md) {
                color: $white;
                flex: 1 1 auto;
            }
        }
    }
}

.page-header {
    min-height: 100px;

    @include media-breakpoint-up(md) {
        min-height: 200px;
    }
}

// Secondary menu
.site-top-secondary-menu {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    margin: 0 -0.5rem;
    transform: translateX(0);
    transition: all 0.25s ease-in;

    &__wrapper {
        background: $white;
        color: theme-color("dark-blue");
        width: 100%;
        padding: 0;
    }

    &__item {
        position: relative;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        + .site-top-secondary-menu__item {

            @include media-breakpoint-up(md) {
                margin-left: 0.25rem;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 0.75rem;
            }
        }

        &.dropdown, .dropdown {
            .dropdown-menu {
                min-width: 15rem;

                .dropdown-toggle {
                    &::after {
                        @include caret-right();
                        color: $secondary;
                        margin: auto 0 auto auto;
                    }
                }
            }

            &:hover {
                > .dropdown-menu {
                    background: $white;
                    border: none;
                    border-radius: 15px;
                    box-shadow: $box-shadow-base;
                    padding: 1rem 0;

                    li {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;

                        > a {
                            border-bottom: 1px solid $secondary;
                            display: flex;
                            padding: 0.5rem 0;

                            &, &:hover {
                                color: inherit;
                                text-decoration: none;
                            }
                        }

                        &.current > a {
                            font-weight: 700;
                        }

                        .dropdown-menu {
                            left: 100%;
                            top: 0;

                            .dropdown-toggle {
                                &::after {
                                    @include caret-right();
                                    color: $secondary;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        display: block;
                        margin-top: -0.5rem;
                    }
                }
            }
        }
    }

    &__link {
        display: block;
        font-size: 0.875rem;
        padding: 0.5rem 0;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            border-bottom: 2px solid transparent;
        }

        &.dropdown-toggle {
            &::after {
                color: $secondary;
            }
        }

        &, &:hover {
            color: inherit;
            text-decoration: none;
        }

        &:hover, .active & {
            border-color: theme-color("dark-blue");
        }
    }
}

// Mobile menu
.navbar-mobile {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }

    .navbar-login,
    .navbar-search,
    .navbar-toggle {
        position: fixed;
        border: none;
        background: transparent;
        padding: 0;
        top: 5px;
        z-index: 1100;
        outline: none;
        width: 50px;
        height: 50px;
        display: none;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            display: flex;
        }
    }

    .navbar-login,
    .navbar-search {
        transition: opacity ease-in-out 0.25s;

        body.show-menu & {
            opacity: 0;
            visibility: hidden;
        }
    }

    .navbar-login {
        right: calc(90px + #{$spacer});

        .customer-icon {
            > svg {
                fill: $white;
                stroke: none;
                width: 2.25rem;
            }
        }
    }

    .navbar-search {
        right: calc(40px + #{$spacer});

        .search-icon {
            > svg {
                fill: none;
                stroke: $white;
            }
        }
    }

    .navbar-toggle {
        right: $spacer;

        .icon-bar {
            transition: all;
            transition-duration: 0.1s;
            transition-delay: 0.3s;
            margin: 0;
            position: absolute;
            opacity: 0;
            display: block;
            width: 30px;
            height: 2px;
            border-radius: 1px;
            background-color: $white;

            &:first-child {
                transform: rotate(45deg);
                opacity: 1;
            }

            &:last-child {
                transform: rotate(-45deg);
                opacity: 1;
            }
        }

        &.collapsed {
            position: absolute;

            .icon-bar {
                opacity: 1;

                &:first-child {
                    transform: translateY(-10px);
                }

                &:last-child {
                    transform: translateY(10px);
                }
            }
        }
    }
}

.mobile-search {
    margin: 0.5rem 0 0 0;
    max-width: 400px; 

    .form-control {
        background: url("/dist/img/search.svg") $white center no-repeat;
        background-position: right center;
        background-size: contain;
        border: 1px solid $secondary;
        border-radius: 15px;
        padding-right: 40px;
    }
}

.navbar-collapse {
    @include media-breakpoint-down(md) {
        position: fixed;
        overflow-y: scroll;
        background: rgba($primary, 0.9);
        z-index: 100;
        top: 0;
        right: -100%;
        width: 100%;
        padding: 60px $spacer;
        height: 100% !important;
        transition: all 0.2s;
        align-items: flex-start;

        &.show {
            right: 0;
        }
    }
}