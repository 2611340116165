﻿@import "../mixins.scss";

.site-footer {
    color: $white;
    background: theme-color("dark-blue");
    min-height: 200px;

    .pre-footer {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(theme-color("dark-blue"), 0.4);
        }

        &__caption {
            @extend .container;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .social-icons {
        display: flex;
        margin: 0;

        .social-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $secondary;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            transition: all ease-in-out 0.25s;

            > svg {
                fill: $white;
                width: 1.5em;
                height: 1.5em;
            }
        }

        a:hover {
            .social-icon {
                border-color: $white;

                > svg {
                    fill: $secondary;
                }
            }
        }
    }

    .site-footer-company,
    .site-footer-articles,
    .site-footer-contact {
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .site-footer-articles,
    .site-footer-contact {
        &__header {
            position: relative;
            padding-left: 60px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 30px;

            &:before {
                content: "";
                position: absolute;
                background-position: center center;
                background-repeat: no-repeat;
                transform: translateX(-100%);
                margin-left: -10px;

                @include subjectIcon();
            }
        }
    }

    .site-footer-articles {
        &__header:before {
            background-image: url("/dist/img/writing.svg");
        }

        .article-item {
            padding: 10px;

            .article-item-image-wrapper {
                border: 1px solid $secondary;
                border-radius: 15px;
                overflow: hidden;

                img {
                    filter: grayscale(1);
                    transform: scale(1);
                    transition: .5s ease-in-out;
                }
            }

            &__name {
                color: $white;
                display: block;
                font-size: 0.8rem;
                font-weight: 700;
                margin-top: 0.3rem;
            }

            &__link {
                display: flex;
                flex-direction: column;

                &:hover {
                    .article-item-image-wrapper img {
                        filter: grayscale(0);
                        transform: scale(1.15);
                    }
                }
            }
        }
    }

    .site-footer-contact {
        &__header:before {
            background-image: url("/dist/img/contact.svg");
        }
    }

    .site-footer-company {
        color: #bfc0c0;
        display: flex;
        flex-direction: column;

        .site-footer-company__logo-wrapper {
            display: flex;
            margin: 10px auto 30px auto;

            img {
                max-height: 100px;
            }

            @include media-breakpoint-up(md) {
                margin: 20px 0 30px 0;
            }
        }

        .social-icons-container {
            margin-top: auto;

            .social-icons {
                > li {
                    @include media-breakpoint-up(lg) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .site-footer-articles {
            border-left: 1px solid $secondary;
            border-right: 1px solid $secondary;
        }
    }

    .department-list {
        font-size: 12px;

        &, & a {
            color: theme-color("medium-gray");
        }

        > li {
            padding: 15px 0;

            &:not(:last-child) {
                border-bottom: 1px solid $secondary;
            }
        }

        &__header,
        &__address {
            margin: 0;
        }

        &__header {
            color: $white;
            font-size: 1rem;
        }
    }

    .site-footer-bottom {
        height: 80px;
        background: theme-color("medium-blue");

        @include media-breakpoint-down(sm) {
            height: 120px;
            padding-bottom: 2rem;
        }

        .site-footer-nav {
            > ul {
                margin: 0;
                padding: 0;

                > li {
                    text-transform: uppercase;

                    &:not(:last-child) {
                        margin-right: 2.25rem;
                    }

                    a {
                        color: $white;
                    }
                }
            }
        }

        .site-footer-bottom__container {
            display: flex;
            align-items: center;
            height: 100%;

            .social-icons {
                .social-icon {
                    width: 34px;
                    height: 34px;

                    > svg {
                        @include media-breakpoint-down(sm) {
                            width: 1em;
                            height: 1em;
                        }
                    }
                }
            }

            .copyright {
                display: flex;
                flex-direction: column;
                margin-left: auto;
                color: theme-color("medium-gray");
                font-size: 0.75rem;
                text-align: right;

                a {
                    color: inherit;
                }
            }

            @include media-breakpoint-down(md) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
