﻿@import "../variables.scss";
@import "../mixins.scss";

.form-search {
    width: 265px;
    max-width: 100%;

    .field-wrapper {
        position: relative;

        input[type="search"] {
            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
        }

        .form-control,
        .custom-select {
            border-radius: 15px;
            border: 1px solid $secondary;
            background-color: $white;
        }

        .search-icon {
            display: inline-flex;
            height: 100%;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;

            > svg {
                fill: none;
                stroke: $secondary;
                height: auto;
            }
        }
    }
}
