﻿@import "../variables.scss";
@import "../mixins.scss";

.textblock-area {
    text-align: center;
    margin: 0 auto;
    max-width: 1140px;

    &__tag-line {
        color: $secondary;
        font-size: 1.3rem;
        display: block;
        margin-bottom: 0.25rem;
    }

    &__divider {
        position: relative;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1px;
        width: 350px;
        max-width: 100%;
        margin: 1rem auto;
        background-color: $secondary;

        > svg {
            width: 30px;
            height: 30px;
            fill: $secondary;
        }

        &.-with-icon {
            background: none;
            height: 30px;

            &:before, &:after {
                content: "";
                background-color: $secondary;
                width: 120px;
                height: 1px;
                position: absolute;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
