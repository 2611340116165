﻿@import "../../node_modules/taggd/dist/taggd.css";

@import "../variables.scss";
@import "../mixins.scss";

.image-map-container {
    position: relative;

    @include media-breakpoint-up(lg) {
        figure {
            body.homepage & {
                margin-top: -100px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        figure {
            max-width: 400px;
            margin: 0 auto;
        }
    }
}

.taggd__button {
    display: block;
    background-color: $secondary;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    margin-left: -1rem;
    margin-top: -1rem;

    &:after {
        content: "";
        border: 2px solid $white;
        box-shadow: 0px 0px 1px 3px rgba($black, 0.15);
        border-radius: 50%;
        position: absolute;
        left: 0;
        width: 2.2rem;
        height: 2.2rem;
        transform: translate(-50%, -50%);
        transition: 0.2s all;
        opacity: 0;
    }

    &:hover {
        background-color: $secondary;
        background-image: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 10;

        &:after {
            opacity: 1;
        }
    }
}

.taggd__popup {
    display: block;
    padding: .75rem;
    position: absolute;
    left: 50%;
    top: 125%;
    color: #707070;
    text-align: center;
    white-space: nowrap;
    background-color: $white;
    border: 1px solid $secondary;
    border-radius: 5px;
    transform: translateX(-50%);
    z-index: 5;

    p {
        margin: 0;
    }
}

.taggd__editor-input,
.taggd__editor-button {
    padding: .25rem .5rem;
    border: 1px solid #fff;
    margin-right: .25rem;
    background-color: transparent;
    color: #fff;
}

.taggd__editor-button--delete {
    margin-right: 0;
}