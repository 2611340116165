﻿@import "../mixins.scss";

.house-info {
    margin-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &__image {
        img {
            border-radius: 15px;
        }
    }

    &__name {
        margin: 1rem 0;
    }
}