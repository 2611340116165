@import "../mixins.scss";

.block-boxes {
    .box-container {
        .box-item {
            display: flex;
            flex-direction: column;

            .box-item-image-wrapper {
                border-radius: 15px;
                overflow: hidden;
                background: #efefef;
                flex: 1;

                img {
                    filter: grayscale(1);
                    transform: scale(1);
                    transition: 0.5s ease-in-out;
                }
            }

            &:hover {
                .box-item-image-wrapper img {
                    filter: grayscale(0);
                    transform: scale(1.15);
                }
            }

            .box-item-caption-container {
                position: relative;
                transform: translateY(-50%);
                display: flex;
                justify-content: center;

                &__icon {
                    transform: translateY(-50%);
                    position: absolute;
                    top: 0;
                    z-index: 5;

                    @include subjectIcon();
                }
            }

            .box-item-caption {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $primary;
                color: $white;
                padding: 0.3rem 0.5rem;
                margin: auto auto 0 auto;
                min-height: 90px;
                width: 90%;
                box-shadow: 0px 0px 40px $black;
                border-radius: 20px;
                position: relative;
                overflow: hidden;

                @include media-breakpoint-up(lg) {
                    min-height: 100px;
                }

                &:before, &:after {
                    content: "";
                    position: absolute;
                    clear: both;
                    transition: all ease-in-out 0.25s;
                    transform: translate(-50%, 100%);
                }

                &:before {
                    left: 50%;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $secondary;
                }

                &:after {
                    left: 50%;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background: $secondary;
                }

                &__title {
                    font-size: 0.8rem;
                    font-weight: 400;
                    text-align: center;
                    text-transform: uppercase;
                    position: relative;
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        font-size: 0.9rem;
                    }
                }
            }

            .box-item-link {
                text-decoration: none;

                &:hover {
                    .box-item-caption {

                        &:before, &:after {
                            transform: translate(-50%, 0);
                        }

                        h4 {
                            > span {
                                display: none;
                                opacity: 0;
                            }

                            &:before {
                                content: attr(data-hover);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
