﻿@import "../variables.scss";
@import "../mixins.scss";

form {
    .field-validation-error {
        color: theme-color("danger");
    }
}

.umbraco-forms {
    &-standard {
        .umbraco-forms-caption {
            margin-left: 0.5rem;
        }

        .umbraco-forms-field {
            .umbraco-forms-field-wrapper {

                .form-control,
                .custom-file-label {
                    border-radius: 15px;
                    border: 1px solid $secondary;
                    background-color: $white;
                }

                .custom-file-label {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 20%;

                    &::after {
                        border-radius: 15px;
                        border: 1px solid $secondary;
                        background-color: #f5f5f5;
                    }
                }

                .custom-control {
                    &.custom-checkbox {
                        .custom-control-label::before {
                            border: 1px solid $secondary;
                            border-radius: 5px;
                        }
                    }
                }

                textarea.form-control {
                    min-height: 14rem;
                }
            }
        }
    }
}