@import "../variables.scss";
@import "../mixins.scss";

.slider-gallery {
    width: 100%;
    height: auto;

    .swiper-slide {
        font-size: 16px;
    }

    .gallery-navigation {
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        @extend .container;

        .swiper-button-prev,
        .swiper-button-next {
            color: $white;
        }
    }

    .swiper-pagination {
        bottom: 15px;

        .swiper-pagination-bullet {
            position: relative;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            color: $black;
            background: $white;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            &:before {
                content: '';
                display: block;
                position: absolute;
                background: $secondary;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                top: calc(50% - 5px);
                left: calc(50% - 5px);
            }
        }
    }

    .slide-caption-container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 20px 40px;
        transform: translateY(-50%);

        @extend .container;

        .slide-caption {
            h3, p {
                color: $white;
                margin-bottom: 1rem;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: left;
            padding: 30px 80px;

            .slide-caption {
                max-width: 80%;

                .btn {
                    margin-top: 1rem;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .slide-caption {
                max-width: 50%;

                h3, p {
                    margin-bottom: 1.5rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            text-align: center;

            .slide-caption {
                h3 {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
