﻿@import "../variables.scss";
@import "../mixins.scss";

.department-map-area {
    background: #f5f5f5;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        background: #f5f5f5;
        width: 100vw;
        top: 0;
        bottom: 0;
        transform: translateX(-100%);
        z-index: -1;
    }

    &__wrapper {
        display: flex;
    }

    &__map {
        flex: 0 0 350px;
        max-width: 350px;

        @include media-breakpoint-up(xl) {
            flex: 0 0 400px;
            max-width: 400px;
        }

        .image-map-container {
            transform: translateY(50px);
            padding-right: 2rem;
        }
    }

    .department-grid {
        display: flex;
        flex: 1 1 auto;
        max-width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        .department-item {
            padding: 0.5rem;
            flex: 0 0 100%;
            max-width: 100%;
            transition: all ease-in-out 0.25s;

            @include media-breakpoint-up(xl) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            &__inner {
                border-radius: 15px;
                border: 1px solid transparent;
                padding: 1rem;
            }

            &.active {
                .department-item__inner {
                    background: $white;
                    border-color: $secondary;
                }
            }

            &__name {
                color: $black;
                font-weight: 700;
                margin: 0;
            }

            &__address {
                color: $gray-base;
                margin: 0;
            }

            &__phone,
            &__email {
                color: $secondary;
            }
        }

        .department-link {
            text-decoration: none;

            &:hover {
                .department-item__inner {
                    background: rgba($white, 0.5);
                    border-color: rgba($secondary, 0.5);
                    border-style: dashed;
                }
            }
        }
    }
}