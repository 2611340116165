﻿@import "../variables.scss";
@import "../mixins.scss";

.icon-boxes-container {
    display: flex;
    justify-content: center;
    margin-bottom: 150px;

    @include media-breakpoint-down(lg) {
        max-width: 95vw;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 250px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: -100px;
    }

    .icon-boxes-tabs {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            transform: translateY(-50%);
            position: absolute;
        }

        &__tablist {
            display: flex;
            list-style: none;
            margin: 0 auto -2px auto;
            padding: 0;
        }

        &__tab {
            display: inline-flex;
            margin-right: -0.25rem;

            @include media-breakpoint-down(sm) {
                font-size: 0.75rem;
            }

            button {
                border: none;
                position: relative;
                z-index: 1;
                background: $secondary;
                color: $white;
                text-transform: uppercase;
                padding: 0.8rem 1rem;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                border-bottom: 0;

                @include media-breakpoint-up(lg) {
                    padding: 0.8rem 3.25rem;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 0.7rem;
                    padding-left: 0.9rem;
                    padding-right: 0.9rem;
                }

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 2rem;
                    height: 2rem;
                    margin-bottom: -1px;
                    pointer-events: none;
                    background-position: left bottom;
                    background-size: cover;

                    @include tabCurve($color: $secondary);

                    @include media-breakpoint-down(sm) {
                        width: 1rem;
                        height: 1rem;

                        @include tabCurveSmall($color: $secondary);
                    }
                }

                &::before {
                    left: 0;
                    transform: translateX(-100%) scaleX(-1);

                    @include media-breakpoint-down(sm) {
                        margin-left: 1px;
                    }
                }

                &::after {
                    right: 0;
                    transform: translateX(100%) scaleX(1);

                    @include media-breakpoint-down(sm) {
                        margin-right: 1px;
                    }
                }
            }

            &[aria-selected="true"] {

                button {
                    background: $white;
                    color: $black;
                    z-index: 3;

                    &::before,
                    &::after {
                        @include tabCurve($color: $white);

                        @include media-breakpoint-down(sm) {
                            @include tabCurveSmall($color: $white);
                        }
                    }
                }
            }
        }

        .icon-boxes-group[role="tabpanel"] {
            background-color: $white;
            box-shadow: 0px 0px 40px rgba(0,0,0,0.15);
            position: relative;
            padding: 0 0.5rem 0.5rem 0.7rem;
            border-radius: 25px;
            z-index: 2;

            @include media-breakpoint-up(lg) {
                min-width: 640px;
            }

            @include media-breakpoint-down(sm) {
                border-radius: 15px;
            }
        }
    }

    .icon-boxes {
        border-radius: 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0 auto;
        padding: 15px 0;
        transform: none;

        .icon-boxes-box {
            position: relative;
            margin: 0;
            flex: 0 0 auto;
            min-width: 120px;
            max-width: 240px;
            max-height: 120px;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            @include media-breakpoint-up(md) {
                &:not(:last-child) {
                    &::after {
                        content: "";
                        height: 100%;
                        width: 1px;
                        right: 0;
                        top: 0;
                        background: $secondary;
                        position: absolute;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &:not(:nth-child(even)) {
                    &::after {
                        content: "";
                        height: 100%;
                        width: 1px;
                        right: 0;
                        top: 0;
                        background: $secondary;
                        position: absolute;
                    }
                }
            }

            &-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 5px;
                position: relative;
                overflow: hidden;
                height: 100%;

                &__icon {
                    margin-bottom: 15px;
                    opacity: 1;
                    transition: all ease-in-out 0.25s;
                }

                .icon-boxes-box-caption {
                    transition: all ease-in-out 0.25s;
                    transform: none;
                    padding: 0 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    &__title {
                        text-transform: uppercase;
                        font-size: 0.8rem;
                        font-weight: 400;
                        padding-bottom: 3px;
                        border-bottom: 1px solid transparent;

                        @include media-breakpoint-up(lg) {
                            font-size: 0.9rem;
                        }

                        @include media-breakpoint-up(xl) {
                            font-size: 1rem;
                        }
                    }

                    &__text {
                        font-size: 14px;
                        opacity: 0;
                        position: relative;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: block;
                        width: 100%;
                        transition: opacity ease-in-out 0.25s;
                    }
                }
            }

            &__link {
                color: #000;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                height: 100%;

                &:hover {
                    .icon-boxes-box-inner {
                        &__icon {
                            height: 0;
                            opacity: 0;
                            transform: scale(0);
                        }
                    }

                    .icon-boxes-box-caption {
                        margin-top: 0;

                        &__title {
                            border-color: $secondary;
                        }

                        &__text {
                            opacity: 1;

                            &:after {
                                content: '';
                                background-image: url("/dist/img/arrow-circle.svg");
                                background-repeat: no-repeat;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                height: 20px;
                                width: 20px;
                                margin: 0 auto -25px auto;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                min-width: 140px;

                &-inner {
                    padding: 5px 10px;
                }
            }

            @include media-breakpoint-up(xl) {
                min-width: 180px;
            }

            @include media-breakpoint-down(xl) {
                flex: 0 0 16.6667%;
                max-width: 16.6667%;
            }

            @include media-breakpoint-down(md) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 50%;
                max-width: 50%;

                &:nth-child(2n) {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 30px;
    }
}
