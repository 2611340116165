﻿@import "../../mixins.scss";

.block-house-list {
    margin-bottom: 5rem;

    .block-house {
        margin: 2rem 0;

        &__inner {
            background: $white;
            border-radius: 15px;
            box-shadow: $box-shadow-base;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            max-width: 90vw;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                padding: 2rem;
            }
        }

        &__link {
            color: inherit;
            text-decoration: none;
        }

        &__image {
            flex: 0 0 100%;
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                flex: 0 0 240px;
                max-width: 240px;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 400px;
                max-width: 400px;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 2rem;
            }

            img {
                border-radius: 15px;
            }
        }

        &__text {
            flex: 1;

            @include media-breakpoint-up(lg) {
                padding-left: 1rem;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 2rem;
            }
        }
    }
}