﻿@import "../variables.scss";
@import "../mixins.scss";

.block-focus-points {
    margin: 30px 0;

    &__heading {
        color: $secondary;
        font-weight: 400;
        text-align: center;
    }

    &-list-wrapper {
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;

        > ul {
            margin: 0;
            padding: 1rem 0;
            column-count: 1;

            @include media-breakpoint-up(sm) {
                column-count: 2;
            }

            @include media-breakpoint-up(lg) {
                column-count: 3;
            }

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

            > li {
                padding: 0.3rem 0;
            }
        }
    }
}
